import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import InternalImage from '../../components/InternalImage';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageBanner styleId={1} title={'Hackaviz 2021'} description={'La quatrième édition du Hackaviz revient en Septembre !'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <p>{`L’association Toulouse Dataviz (TDV) organise son HACKAVIZ, un concours de visualisation de données en temps limité, seul ou en équipe, doté de récompenses, ouvert à un très large public.`}</p>
        <InternalImage name={'hackaviz2021'} altText={''} mdxType="InternalImage" />
        <h1>{`LE PRINCIPE`}</h1>
        <p>{`Raconter une histoire avec des graphiques, à partir d’un jeu de données inédit, seul ou en équipe.
A partir du moment ou les données sont mises à disposition, les candidats ont 10 jours pour rendre leur création.`}</p>
        <ul>
          <li parentName="ul">{`Date de mise à disposition des données : `}<strong parentName="li">{`9 Septembre 2021`}</strong></li>
          <li parentName="ul">{`Date limite de remise des contributions : `}<strong parentName="li">{`19 Septembre 2021`}</strong></li>
          <li parentName="ul">{`Hackaviz Party : Présentation des réalisations et remise des prix : `}<strong parentName="li">{`1er Octobre 2021`}</strong></li>
        </ul>
        <h1>{`PRÉPARER L’HACKAVIZ`}</h1>
        <p>{`Voici des newsletters pour vous préparer à l'Hackaviz.`}</p>
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-13--spcial-hackaviz-2021'} text={'#13 - Spécial Hackaviz 2021'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-14--spcial-hackaviz-2021'} text={'#14 - Spécial Hackaviz 2021'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-15--spcial-hackaviz-2021'} text={'#15 - Spécial Hackaviz 2021'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-16--spcial-hackaviz-2021'} text={'#16 - Spécial Hackaviz 2021'} mdxType="Button" />
        <h1>{`LE JEU DE DONNÉES`}</h1>
        <p>{`Cette année, nous proposons dans un jeu de données inédit :`}</p>
        <ul>
          <li parentName="ul">{`les caractéristiques démographiques et économiques des quartiers prioritaires`}</li>
          <li parentName="ul">{`les transactions foncières du 1er janvier 2016 au 31 décembre 2020.`}</li>
          <li parentName="ul">{`les contours géographiques des communes et des quartiers prioritaires`}</li>
        </ul>
        <Button link={'/hackaviz/2021-data'} type={'internal'} text={'Télécharger les données'} mdxType="Button" />
        <h1>{`LES RÈGLES`}</h1>
        <ul>
          <li parentName="ul">{`Les candidats peuvent être des individus ou des équipes. Le nombre de personnes regroupées sous une candidature doit être précisé à l’inscription. Une fois inscrit, le candidat – ou l’équipe – s’engage à envoyer sa contribution avant la date de fin du concours.`}</li>
          <li parentName="ul">{`Les candidats s’engagent à autoriser la publication de leur contribution sur le site toulouse-dataviz.fr`}</li>
          <li parentName="ul">{`Les candidats s’engagent à être présents ou représentés ou à défaut disponible par vidéo lors de l’Hackaviz Party du 1er octobre 2021.`}</li>
          <li parentName="ul">{`Les candidats peuvent ensuite utiliser tous les outils qu’ils souhaitent pour explorer ces données et en proposer une représentation visuelle à base de graphiques.`}</li>
          <li parentName="ul">{`Les candidats peuvent effectuer tous types de calcul à partir de ce jeu de données`}</li>
          <li parentName="ul">{`Les candidats enverront leur contribution par mail avant la date limite à une adresse qui sera communiquée lors de la mise à disposition des données.`}</li>
          <li parentName="ul">{`Le format de restitution de cette analyse visuelle sera au `}<strong parentName="li">{`format pdf`}</strong>{` et `}<strong parentName="li">{`ne devra pas dépasser l’équivalent de 2 pages A4`}</strong>{` ou l’équivalent de `}<strong parentName="li">{`3 captures d’écran`}</strong>{` si la réalisation est sur le web.`}</li>
          <li parentName="ul">{`Dans le cas d’une création interactive ou utilisant des services web, le candidat (ou l’équipe) enverra un mail contenant uniquement l’URL valide de la réalisation.`}</li>
          <li parentName="ul">{`Les candidats s’attacheront à ajouter des éléments contextuels pour commenter le ou les graphiques.`}</li>
          <li parentName="ul">{`Les candidats fourniront la liste des outils utilisés pour la réalisation des graphiques`}</li>
          <li parentName="ul">{`Les participants n’ont pas l’obligation d’utiliser toutes les données.`}</li>
          <li parentName="ul">{`Hormis des fonds de carte, `}<strong parentName="li">{`les candidats ne sont pas autorisés à utiliser d’autres données que celles fournies`}</strong>{`.`}</li>
        </ul>
        <h1>{`ÉVALUATION`}</h1>
        <p>{`Les contributions seront jugées par un jury de spécialistes proposé par les organisateurs. Ces personnalités n’auront pas le droit de concourir à l’Hackaviz, ni de collaborer avec aucune des équipes en compétition.`}</p>
        <p>{`Les contributions seront évaluées en fonction de différents critères dont les suivants (liste non exhaustive, ordre non priorisé).`}</p>
        <ul>
          <li parentName="ul">{`Pertinence pour servir la narration/histoire abordée.`}</li>
          <li parentName="ul">{`Originalité`}</li>
          <li parentName="ul">{`Maîtrise des règles de l’art`}</li>
          <li parentName="ul">{`Concision`}</li>
        </ul>
        <p>{`Le jury de l’Hackaviz est souverain, ses décisions sont donc définitives. Le jury se réserve le droit de pénaliser les contributions qui ne respecteraient pas les règles. Les résultats seront annoncés lors de l’Hackaviz Party .`}</p>
        <h1>{`HACKAVIZ PARTY`}</h1>
        <p>{`L’hackaviz Party se déroulera dans le cadre `}<strong parentName="p">{`des rencontres de la dataviz`}</strong>{` de la `}<a parentName="p" {...{
            "href": "https://www.meleenumerique.com/programme/"
          }}>{`mêlée numérique`}</a>{` le 1 oct 2020 à partir de 18:30 au quai des savoirs à Toulouse
Avant la cérémonie de remise des prix, tous les participants pourront présenter leur réalisation et échanger autour de stands mis à leur disposition.
Tous les candidats seront appelés à commenter leur réalisation sous forme de “pitch rapide” lors de la remise des prix.
L’Hackaviz Party sera filmée en direct pour permettre aux participants ne pouvant se déplacer de voir l’événement à distance.`}</p>
        <HackavizFooter year={'2021'} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      